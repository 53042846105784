import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { useEffect } from 'react';


interface Props {
    getTableColumns?: (data: any) => void,
    getTaskDetails:(data: any)=>void
  };
  
  export const ReportTableData: React.FC<Props> = ({
    getTableColumns = () => { },
    getTaskDetails  = () => { }
  }) => {

    useEffect(() => {    
        getTableColumns(REPORTS_COLS);
    }, [getTableColumns]);


const statusList = [
    { label: 'Active', value: 'Active' },
    { label: 'Blocked', value: 'Blocked' },
    { label: 'Deleted', value: 'Deleted' },
    { label: 'Not Activated', value: 'Not Activated' }
];

const NetworkList = [
    { label: 'True', value: 'True' },
    { label: 'False', value: 'False' }
];

const languageList = [
    { label: 'Base', value: 'Base' },
    { label: 'Alternate', value: 'Alternate' }
];

      const getInspectionTaskDetails = (data: any) => {
          getTaskDetails(data);
      }

const networkEditor = (options) => {
    let dataList = options?.rowData?.taskdata;
  
    return (

        <Dropdown panelClassName='panel-custom-style' placeholder='Select a task'  options={dataList} optionLabel="name" optionValue="id"
        itemTemplate={(option) => {
            return <div className='data-template'  onClick={()=>getInspectionTaskDetails(option.id)} >{option.name}</div>
        }}
            />
    );
}

const statusEditor = (options) => {
    return (
        <Dropdown value={options.value} options={statusList} disabled optionLabel="label" optionValue="value"
            placeholder="Select Status"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const languageEditor = (options) => {
    return (
        <Dropdown value={options.value} options={languageList} optionLabel="label" optionValue="value"
            itemTemplate={(option) => {
                return <span className='status-badge'>{option.label}</span>
            }} />
    );
}

const textEditor = (options) => {
    return <InputText type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} />;
}
const disableEditor = (options) => {
    return <InputText type="text" value={options.value} disabled onChange={(e) => options.editorCallback(e.target.value)} />;
}

 const REPORTS_ROWS = [
    {
        Name: 'Systems',
        SystemType: 'MXL',
        IsNetworked: 'True',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'XLS',
        IsNetworked: 'False',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'XLS',
        IsNetworked: 'True',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },
    {
        Name: 'Systems',
        SystemType: 'Desigo Modular',
        IsNetworked: 'False',
        GlobalPMI: '2+Global PMI or 1+NC',
        PreferredLanguage: 'Base',
        Status: 'Active',
        XMLFile: 'Yes',
        ConfigFile: 'No',
        ActiveTask: 'Task 106',
    },

];

 const REPORTS_COLS = [
    {
        field: 'template',
        header: 'Template',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },
    {
        field: 'reportBy',
        header: 'Report by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'groups',
        header: 'Groups',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 100px' },
        sortable: true,
    },
    {
        field: 'customer',
        header: 'Customer',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 250px' },
        sortable: true,
    },
    {
        field: 'contract',
        header: ' Contract',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 300px' },
        sortable: true,
    },
    // {
    //     field: 'taskDetails',
    //     header: 'Inspection Tasks',
    //     editor: (options) => disableEditor(options),
    //     style: { flex: '1 0 170px' },
    //     sortable: false,

    // },
    {
        field: 'buildingNames',
        header: 'Buildings Name(s)',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 160px' },
        sortable: true,
    },
    {
        field: 'dateAndTimeCreated',
        header: 'Date and Time created',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 180px' },
        sortable: true,
    },
    {
        field: 'createdBy',
        header: 'Created by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px' },
        sortable: true,
    },

    {
        field: 'dateAndTimeUploaded',
        header: 'Date and Time uploaded',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 190px', color: '#443DF6' },
        sortable: true,
        headerClassName: 'date-time'

    },
    {
        field: 'daysToUpload',
        header: 'Days to upload',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 140px', display: 'flex', justifyContent: 'end' },
        sortable: true,

    },
    {
        field: 'addedBy',
        header: 'Added by',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 150px', color: '#443DF6' },
        sortable: true,
        headerClassName: 'date-time'

    },
    {
        field: 'sendToServicePortal',
        header: 'Sent to Service Portal',
        editor: (options) => disableEditor(options),
        style: { flex: '1 0 170px' },
        sortable: true,

    }
    

];
return (
    <>
    </>
  )
}
export default ReportTableData;